.errortext{

    color: red;
    font-size: 14px;
}



@media(max-width:768px){

    .registerform{

        width: 100%;
    }

    .css-syfobq {
        width: 80% !important;
        padding: var(--chakra-space-8);
        max-width: 500px;
        border-width: 1px;
        border-radius: 8px;
        box-shadow: var(--chakra-shadows-lg);
    }   
}