@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

html{
  margin: 0;
  padding: 0;
}

body{
  overflow-x: hidden;
 
}
 