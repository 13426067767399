
.loginError{
    color: red;
    font-size: 14px;
}


@media(max-width:768px){

    .loginform{

        width: 100%;
    }
}